import './src/styles.css';

import splitbee from '@splitbee/web';

splitbee.init({
  token: 'X4DAM0JMFBPU',
  disableCookie: true,
  scriptUrl: '/bee.js',
  apiUrl: '/_hive',
});
